import { StoryblokBubbleList } from '@wh/common/cms/components/bloks/StoryblokBubbleList'
import { StoryblokPictureWithBlueTextBottom } from '@wh/common/cms/components/bloks/StoryblokPictureWithBlueTextBottom'
import { useBapStartPageDigitalAdvertising } from '@bbx/common/digital-advertising/hooks/useBapStartPageDigitalAdvertising'
import { NextDynamicRoute } from '@bbx/common/routing/nextDynamicRoutes'
import { Category } from '@bbx/search-journey/common/Category'
import { bapLinkGroup } from '@bbx/search-journey/common/components/BapLinkVomit/BapLinkVomit'
import { jobsLinkGroup } from '@bbx/search-journey/common/components/JobsLinkVomit/JobsLinkVomit'
import { LinkVomit } from '@bbx/search-journey/common/components/LinkVomit/LinkVomit'
import { Navigator, RangeNavigator } from '@bbx/search-journey/common/Navigators'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { useStartPageSearch } from '@bbx/search-journey/sub-domains/search/components/common/start-pages/useStartPageSearch'
import { createBrowsePageUrl } from '@bbx/search-journey/sub-domains/search/components/verticals/bap/start-browse-pages/lib/createBrowsePageUrl'
import { getFlatPossibleValues, getNavigatorById } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { Box } from '@wh-components/core/Box/Box'
import { Divider } from '@wh-components/core/Divider/Divider'
import CatAnimals from '@wh-components/icons/CatAnimals'
import CatAntique from '@wh-components/icons/CatAntique'
import CatBaby from '@wh-components/icons/CatBaby'
import CatBeauty from '@wh-components/icons/CatBeauty'
import CatBoats from '@wh-components/icons/CatBoats'
import CatBooks from '@wh-components/icons/CatBooks'
import CatCartools from '@wh-components/icons/CatCartools'
import CatComputer from '@wh-components/icons/CatComputer'
import CatConsole from '@wh-components/icons/CatConsole'
import CatFashion from '@wh-components/icons/CatFashion'
import CatFree from '@wh-components/icons/CatFree'
import CatGames from '@wh-components/icons/CatGames'
import CatGarden from '@wh-components/icons/CatGarden'
import CatLiving from '@wh-components/icons/CatLiving'
import CatMultimedia from '@wh-components/icons/CatMultimedia'
import CatMusic from '@wh-components/icons/CatMusic'
import CatPhone from '@wh-components/icons/CatPhone'
import CatServices from '@wh-components/icons/CatServices'
import CatSport from '@wh-components/icons/CatSport'
import CatWatch from '@wh-components/icons/CatWatch'
import { hasKey } from '@wh/common/chapter/lib/functionalHelpers'
import React, { Fragment, FunctionComponent } from 'react'
import { TrendCarousel } from '../../../../../../common/components/TrendCarousel/TrendCarousel'
import { CategoryArea } from './CategoryArea/CategoryArea'
import { AzaRow } from './ContentArea/AzaRow'
import { ContentArea } from './ContentArea/ContentArea'
import { SearchBox } from './SearchEntryArea/SearchBox'
import { SearchEntryArea } from './SearchEntryArea/SearchEntryArea'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { StartPageTopAdsWidget } from '@bbx/search-journey/sub-domains/search/components/common/start-pages/ContentArea/StartPageTopAdsWidget'

export interface StartPageContainerProps {
    initialSearchResult: SearchResult
    baseUrl: string
    pageTitle: string
    categoryAreaTitle: string
    extraCategories: Category[]
    popularCategories?: StoryblokPictureWithBlueTextBottom
    popularBrands?: StoryblokBubbleList
}

export const BapStartPageContainer: FunctionComponent<StartPageContainerProps> = ({
    initialSearchResult,
    baseUrl,
    pageTitle,
    categoryAreaTitle,
    extraCategories,
    popularCategories,
    popularBrands,
}) => {
    useBapStartPageDigitalAdvertising()
    const { taggingData } = initialSearchResult

    const { currentSearchResult, isSearchInProgress } = useStartPageSearch(initialSearchResult, NextDynamicRoute.BAP_BROWSE_PAGE)

    const categoryNavigator = getNavigatorById(currentSearchResult, 'category')
    const topLevelCategories = [...mapCategoryPossibleValues(categoryNavigator), ...extraCategories]
    const fetchTopAdsLink = findContextLinkWithId('fetchTopAds', currentSearchResult.searchContextLinks)

    return (
        <Fragment>
            <SearchEntryArea
                nrOfAdverts={currentSearchResult.rowsFound}
                searchBox={
                    <SearchBox
                        currentSearchResult={currentSearchResult}
                        isSearchInProgress={isSearchInProgress}
                        autocompleteSearchTaggingEvent="vertical_home_keyword_search_search"
                        autocompleteAutocompleteTaggingEvent="vertical_home_keyword_search_autocomplete"
                        autocompleteLastSearchTaggingEvent="vertical_home_keyword_search_last"
                    />
                }
                categoryBox={
                    <CategoryArea
                        categories={topLevelCategories}
                        taggingData={taggingData}
                        baseUrl={baseUrl}
                        categoryAreaTitle={categoryAreaTitle}
                        popularCategories={popularCategories}
                        popularBrands={popularBrands}
                    />
                }
                pageTitle={pageTitle}
            />
            <ContentArea
                topContent={
                    <Fragment>
                        <Divider marginBottom="l" display={{ phone: 'none', tablet: 'block' }} />
                        {fetchTopAdsLink && (
                            <StartPageTopAdsWidget
                                title="Top Anzeigen"
                                fetchTopAdsLink={fetchTopAdsLink}
                                taggingData={currentSearchResult.taggingData}
                                taggingEvent="bap_vertical_home_topad_click"
                            />
                        )}
                        <AzaRow
                            azaUrl="/iad/anzeigenaufgabe"
                            taggingData={taggingData}
                            taggingAction="bap_vertical_home_ad_insertion_button_click"
                        />
                        <TrendCarousel title="Aktuelle Trends" taggingData={taggingData} taggingEvent="bap_vertical_home_trend_click" />
                    </Fragment>
                }
                bottomContentWithoutPadding={
                    <Box
                        borderTop={{ phone: 'owl', tablet: 0 }}
                        marginTop={{ tablet: 'm' }}
                        marginHorizontal={{ tablet: 'm' }}
                        testId="link-vomit-wrapper"
                    >
                        <LinkVomit linkGroups={[jobsLinkGroup]} testId="jobs-link-vomit" />
                        <LinkVomit marginTop={{ tablet: 'm' }} linkGroups={[bapLinkGroup]} testId="bap-link-vomit" />
                    </Box>
                }
            />
        </Fragment>
    )
}

export const mapCategoryPossibleValues = (categoryNavigator: Navigator | RangeNavigator): Category[] => {
    return getFlatPossibleValues(categoryNavigator).map((value) => {
        const categoryTreeId = Number(value.urlParamRepresentationForValue[0].value)

        return {
            label: value.label,
            webLink: createBrowsePageUrl(value.webLink?.uri ?? ''),
            categoryTreeId: categoryTreeId,
            Icon: hasKey(categoryToIconMap, categoryTreeId) ? categoryToIconMap[categoryTreeId] : null,
            taggingEvent: 'vertical_home_category_click',
        }
    })
}

export const categoryToIconMap = {
    6941: CatAntique,
    3928: CatBaby,
    3076: CatBeauty,
    5007823: CatBoats,
    387: CatBooks,
    5824: CatComputer,
    537: CatServices,
    6462: CatMusic,
    2785: CatConsole,
    3541: CatGarden,
    6808: CatMultimedia,
    6142: CatCartools,
    3275: CatFashion,
    2691: CatPhone,
    5136: CatGames,
    4390: CatSport,
    4915: CatAnimals,
    2409: CatWatch,
    5387: CatLiving,
    999999: CatFree,
}
