import React, { FunctionComponent, useContext } from 'react'
import { Box, BoxProps } from '@wh-components/core/Box/Box'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { Text } from '@wh-components/core/Text/Text'
import { LazyPicture } from '@wh/common/chapter/components/LazyPicture/LazyPicture'
import HandlerShopX1 from '@bbx/static_hashed/img/bap/startpage/bap_start_webshop.png'
import HandlerShopX2 from '@bbx/static_hashed/img/bap/startpage/bap_start_webshop@2x.png'
import { css } from 'styled-components'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { LinkWithButtonStyle } from '@wh-components/core/Button/LinkWithButtonStyle'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import CatDealershop from '@wh-components/icons/CatDealershop'

interface SparePartsWidgetProps {
    taggingData: TaggingData
}

export const ShopWidget: FunctionComponent<SparePartsWidgetProps & BoxProps> = ({ taggingData, ...props }) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const shopWidgetBorderColor = advertisingState.pageModifications.borders?.['bap-shop-widget']
    const shopWidgetHeaderColor = advertisingState.pageModifications.foregroundColors?.['bap-shop-widget-header'] as string
    const shopWidgetHeaderBackgroundColor = advertisingState.pageModifications.backgroundColors?.['bap-shop-widget-header'] as string
    const shopWidgetBodyColor = advertisingState.pageModifications.foregroundColors?.['bap-shop-widget-body'] as string
    const shopWidgetBodyBackgroundColor = advertisingState.pageModifications.backgroundColors?.['bap-shop-widget-body'] as string

    return (
        <Box
            border="owl"
            borderColor={shopWidgetBorderColor}
            borderRadius="m"
            width="100%"
            flexDirection="column"
            {...props}
            overflow="hidden"
            marginBottom="xxs"
        >
            <Box
                height={40}
                paddingHorizontal="m"
                display="flex"
                alignItems="center"
                color={shopWidgetHeaderColor ?? 'palette.verydarkgrey'}
                testId="vertical-shop-link"
                borderBottom="owl"
                borderColor={shopWidgetBorderColor}
                backgroundColor={shopWidgetHeaderBackgroundColor ?? 'palette.polarbear'}
            >
                <Text fontWeight="bold" fontSize="l">
                    Marktplatz-Shops auf willhaben
                </Text>
            </Box>

            <Box
                paddingLeft="m"
                display="flex"
                justifyContent="space-between"
                color={shopWidgetBodyColor ?? 'palette.verydarkgrey'}
                backgroundColor={shopWidgetBodyBackgroundColor}
            >
                <Box display="flex" flexDirection="column" paddingTop="m">
                    <Text as="p" fontSize="m">
                        Unsere Businesslösung für Unternehmen.
                    </Text>

                    <LinkWithButtonStyle
                        href="https://willshop.willhaben.at/"
                        testId="vertical-shop-information-link"
                        marginTop="sm"
                        marginBottom="m"
                        variant="outline"
                        marginRight="m"
                        size="small"
                        target="_blank"
                        onClick={() => callActionEvent('bap_vertical_home_webshop_info_click', taggingData)}
                        aria-label="Über willhaben-Schops informieren"
                        css={css`
                            background-color: ${shopWidgetBodyBackgroundColor};
                            border-color: ${shopWidgetBorderColor};
                            color: ${shopWidgetBodyColor};
                        `}
                    >
                        <Text fontSize="s">Jetzt informieren</Text>
                    </LinkWithButtonStyle>
                </Box>
                <Box display="flex" alignItems="flex-end">
                    <LazyPicture
                        imgSrcSet={{
                            x1: HandlerShopX1,
                            x2: HandlerShopX2,
                        }}
                        width="112px"
                        height="109px"
                    />
                </Box>
            </Box>
            <Box display="flex" borderTop="owl" padding="m" height={40} backgroundColor={shopWidgetBodyBackgroundColor}>
                <ServerRoutingAnchorLink
                    display="flex"
                    alignItems="center"
                    type="anchor"
                    href="/iad/kaufen-und-verkaufen/webshop-shop-suche/"
                    onClick={() => callActionEvent('bap_vertical_home_webshop_search_click', taggingData)}
                    color={shopWidgetBodyColor ?? 'palette.verydarkgrey'}
                >
                    <CatDealershop marginRight="s" size="medium" color={shopWidgetBodyColor ?? 'palette.primary.main'} />
                    <Text>zur Shop-Übersicht</Text>
                </ServerRoutingAnchorLink>
            </Box>
        </Box>
    )
}
