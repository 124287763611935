import { LinkGroup } from '@wh/common/chapter/types/SeoMetaData'

export const bapLinkGroup: LinkGroup = {
    title: 'Aktuelle Suchen',
    links: [
        { name: 'Ikea', link: '/iad/kaufen-und-verkaufen/l/ikea' },
        { name: 'Louis Vuitton', link: '/iad/kaufen-und-verkaufen/l/louis-vuitton' },
        { name: 'Rasenmäher', link: '/iad/kaufen-und-verkaufen/l/rasenmaeher' },
        { name: 'Rasenmähertraktor', link: '/iad/kaufen-und-verkaufen/l/rasenmaehertraktor' },
        { name: 'Puch Rennrad', link: '/iad/kaufen-und-verkaufen/l/puch-rennrad' },
        { name: 'Brennholz', link: '/iad/kaufen-und-verkaufen/l/brennholz' },
        { name: 'Ortovox', link: '/iad/kaufen-und-verkaufen/l/ortovox' },
        { name: 'Engelbert Strauss', link: '/iad/kaufen-und-verkaufen/l/engelbert-strauss' },
        { name: 'Makita', link: '/iad/kaufen-und-verkaufen/l/makita' },
        { name: 'Pflastersteine', link: '/iad/kaufen-und-verkaufen/l/pflastersteine' },
        { name: 'Tommy Hilfiger', link: '/iad/kaufen-und-verkaufen/l/tommy-hilfiger' },
        { name: 'Tupperware', link: '/iad/kaufen-und-verkaufen/l/tupperware' },
        { name: 'Salewa', link: '/iad/kaufen-und-verkaufen/l/salewa' },
        { name: 'Zara', link: '/iad/kaufen-und-verkaufen/l/zara' },
        { name: 'Vespa Helm', link: '/iad/kaufen-und-verkaufen/l/vespa-helm' },
        { name: 'Ikea Kallax', link: '/iad/kaufen-und-verkaufen/l/ikea-kallax' },
        { name: 'Ktm Mountainbike', link: '/iad/kaufen-und-verkaufen/l/ktm-mountainbike' },
        { name: 'Hebebühne', link: '/iad/kaufen-und-verkaufen/l/hebebuehne' },
        { name: 'Martini', link: '/iad/kaufen-und-verkaufen/l/martini' },
        { name: 'Drehbank', link: '/iad/kaufen-und-verkaufen/l/drehbank' },
        { name: 'Holzspalter', link: '/iad/kaufen-und-verkaufen/l/holzspalter' },
        { name: 'Ikea Pax', link: '/iad/kaufen-und-verkaufen/l/ikea-pax' },
        { name: 'Gucci', link: '/iad/kaufen-und-verkaufen/l/gucci' },
        { name: 'Gartenhütte', link: '/iad/kaufen-und-verkaufen/l/gartenhuette' },
        { name: 'Dynafit', link: '/iad/kaufen-und-verkaufen/l/dynafit' },
    ],
}
