import { StoryblokBubbleList } from '@wh/common/cms/components/bloks/StoryblokBubbleList'
import { StoryblokPictureWithBlueTextBottom } from '@wh/common/cms/components/bloks/StoryblokPictureWithBlueTextBottom'
import { crossBrowserPageBreakStyles } from '@bbx/common/lib/columnLayoutPageBreakStyles'
import { Category } from '@bbx/search-journey/common/Category'
import { getCurrentPositionWithToast } from '@bbx/search-journey/common/lib/getLocation'
import { Box } from '@wh-components/core/Box/Box'
import { ButtonWithLinkStyle } from '@wh-components/core/Button/ButtonWithLinkStyle'
import { Columns } from '@wh-components/core/Columns/Columns'
import { Heading } from '@wh-components/core/Heading/Heading'
import { Text } from '@wh-components/core/Text/Text'
import NearMeStroke from '@wh-components/icons/NearMeStroke'
import { IconType, SvgIconProps } from '@wh-components/icons/utilities/createSvgIcon'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { useScreenSize } from '@wh/common/chapter/components/UserAgentProvider/useUserAgent'
import { BbxRouter } from '@wh/common/chapter/lib/routing/bbxRouter'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { concatPathWithQueryParams, getRelativeUrl } from '@wh/common/chapter/lib/urlHelpers'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { ContentAdLargeRenderSlot } from '@wh/common/digital-advertising/components/ContentAdLargeRenderSlot/ContentAdLargeRenderSlot'
import { ContentAdMediumRenderSlot } from '@wh/common/digital-advertising/components/ContentAdMediumRenderSlot/ContentAdMediumRenderSlot'
import { ContentAdSmallRenderSlot } from '@wh/common/digital-advertising/components/ContentAdSmallRenderSlot/ContentAdSmallRenderSlot'
import React, { createElement, Fragment, FunctionComponent, useContext } from 'react'
import { css } from 'styled-components'
import { categoryRowCss } from '../../../../common/common/CategoryRow/CategoryRow'
import { ShopWidget } from '../ContentArea/ShopWidget'

type CategoryAreaProps = {
    taggingData: TaggingData
    categories: Category[]
    baseUrl: string
    categoryAreaTitle: string
    popularCategories?: StoryblokPictureWithBlueTextBottom
    popularBrands?: StoryblokBubbleList
}

export const CategoryArea: FunctionComponent<CategoryAreaProps> = ({
    categories,
    taggingData,
    baseUrl,
    categoryAreaTitle,
    popularCategories,
    popularBrands,
}) => {
    const advertisingState = useContext(AdvertisingStateContext)

    const categoryBoxBackgroundColor = advertisingState.pageModifications.backgroundColors?.['bap-category-box']
    const categoriesForegroundColor = advertisingState.pageModifications.foregroundColors?.['bap-category-labels']
    const categoryLinesBorderColor = advertisingState.pageModifications.borders?.['bap-category-lines']
    const categoryIconsForegroundColor = advertisingState.pageModifications.foregroundColors?.['bap-category-icons']

    const popularCategoriesIframe = advertisingState.pageModifications.bapSearchEntryPopularCategoriesIframe

    const screenSize = useScreenSize()

    return (
        <Box
            display="flex"
            flexDirection={{ phone: 'column', tablet: 'row' }}
            backgroundColor={categoryBoxBackgroundColor ?? 'inherit'}
            testId="category-area"
            paddingTop={{ phone: 'm', tablet: 'l' }}
            paddingLeft="m"
            paddingRight={{ phone: 'm', tablet: 0 }}
            gap="sm"
        >
            <Box flexGrow={1} marginBottom={{ phone: 'sm', tablet: 0 }}>
                <Box display="flex" flexDirection="column" gap={{ phone: 'm', tablet: 'l' }}>
                    {popularCategoriesIframe ? (
                        <Box width="100%" height={popularCategoriesIframe.height}>
                            <iframe
                                src={popularCategoriesIframe.src}
                                frameBorder="0"
                                title="Werbung"
                                aria-hidden="true"
                                css={css`
                                    display: block;
                                    width: 100%;
                                    height: 100%;

                                    ${(p) => p.theme.media.print} {
                                        display: none;
                                    }
                                `}
                            />
                        </Box>
                    ) : (
                        <Fragment>
                            {popularCategories && (
                                <StoryblokPictureWithBlueTextBottom
                                    blok={popularCategories}
                                    onClickOverride={(item) =>
                                        callActionEvent('vertical_home_popular_category_click', taggingData, {
                                            popularCategory: item.taggingId,
                                        })
                                    }
                                    testId="popular-categories"
                                    categoriesForegroundColor={categoriesForegroundColor}
                                />
                            )}
                            {popularBrands && (
                                <StoryblokBubbleList
                                    blok={popularBrands}
                                    onClickOverride={(item) =>
                                        callActionEvent('vertical_home_make_click', taggingData, { make: item.taggingId })
                                    }
                                    testId="popular-makes"
                                    categoriesForegroundColor={categoriesForegroundColor}
                                />
                            )}
                        </Fragment>
                    )}
                    <Box>
                        <Heading
                            text={categoryAreaTitle}
                            level={2}
                            fontSize="m"
                            marginBottom="s"
                            testId="category-area-heading"
                            color={categoriesForegroundColor ?? 'palette.raccoon'}
                            css={css`
                                text-transform: uppercase;
                            `}
                        />
                        <Columns columns={{ phone: 1, tablet: 2 }} gap="m">
                            <NearMeCategoryItem
                                baseUrl={baseUrl}
                                foregroundColor={categoriesForegroundColor}
                                borderColor={categoryLinesBorderColor}
                                iconForegroundColor={categoryIconsForegroundColor}
                                taggingData={taggingData}
                            />

                            {categories.map((category, index) => (
                                <CategoryItem
                                    key={index}
                                    {...category}
                                    foregroundColor={categoriesForegroundColor}
                                    iconForegroundColor={categoryIconsForegroundColor}
                                    borderColor={categoryLinesBorderColor}
                                    screenSize={screenSize}
                                    taggingData={taggingData}
                                />
                            ))}
                        </Columns>
                    </Box>
                </Box>
            </Box>

            <Box display="flex" flex="0 0 300px" flexDirection="column">
                <Box marginBottom={{ phone: 'sm', tablet: 'l' }}>
                    <ContentAdLargeRenderSlot />
                    <ContentAdMediumRenderSlot />
                    <ContentAdSmallRenderSlot />
                </Box>
                <ShopWidget taggingData={taggingData} />
            </Box>
        </Box>
    )
}

interface CategoryItemProps extends Category {
    foregroundColor: string | undefined
    iconForegroundColor: string | undefined
    borderColor: string | undefined
    screenSize: 'phone' | 'tablet' | 'desktop'
    taggingData: TaggingData
}

const CategoryItem: FunctionComponent<CategoryItemProps> = ({
    label,
    webLink,
    Icon,
    foregroundColor,
    iconForegroundColor,
    borderColor,
    screenSize,
    taggingData,
    taggingEvent,
    categoryTreeId,
}) => {
    return (
        <ClientRoutingAnchorLink
            type="anchor"
            href={getRelativeUrl(webLink)}
            scrollToTop={screenSize === 'phone'}
            css={css`
                ${categoryRowCss(borderColor)}
                ${crossBrowserPageBreakStyles}
            `}
            color={foregroundColor ?? 'palette.verydarkgrey'}
            paddingLeft="xl"
            testId={`category-link-${categoryTreeId}`}
            marginBottom={{ tablet: 'm' }}
            onClick={() => callActionEvent(taggingEvent, taggingData, { category: label })}
        >
            <CategoryLinkContent label={label} Icon={Icon} iconForegroundColor={iconForegroundColor} />
        </ClientRoutingAnchorLink>
    )
}

const NearMeCategoryItem: FunctionComponent<{
    baseUrl: string
    foregroundColor: string | undefined
    iconForegroundColor: string | undefined
    borderColor: string | undefined
    taggingData: TaggingData
}> = ({ baseUrl, borderColor, foregroundColor, iconForegroundColor, taggingData }) => {
    return (
        <ButtonWithLinkStyle
            fontSize="m"
            css={css`
                ${categoryRowCss(borderColor)}
                &:last-of-type {
                    border-bottom: 0;
                }
                ${crossBrowserPageBreakStyles}
                ${(p) => p.theme.media.only.phone} {
                    box-sizing: content-box;
                    width: 100%;
                }
            `}
            onClick={() => {
                callActionEvent('vertical_home_nearme_click', taggingData)
                getCurrentPositionWithToast((lat, lon) =>
                    BbxRouter.push({
                        href: concatPathWithQueryParams(baseUrl, {
                            sort: '2',
                            lat: lat,
                            lon: lon,
                            srcType: 'vertical-home',
                            srcAdd: 'near-me',
                        }),
                        clientSideRouting: true,
                    }),
                )
            }}
            color={foregroundColor ?? 'palette.verydarkgrey'}
            paddingLeft="xl"
            marginBottom={{ tablet: 'm' }}
        >
            <CategoryLinkContent label="Anzeigen in meiner Nähe" Icon={NearMeStroke} iconForegroundColor={iconForegroundColor} />
        </ButtonWithLinkStyle>
    )
}

interface CategoryLinkContentProps {
    label: string
    Icon?: IconType | null
    iconForegroundColor?: string | undefined
}

const CategoryLinkContent: FunctionComponent<CategoryLinkContentProps> = ({ label, Icon, iconForegroundColor }) => {
    const iconProps: SvgIconProps = { size: 'medium', marginRight: 'xs', color: iconForegroundColor ?? 'palette.primary.main' }

    return (
        <Box display="flex" alignItems="center">
            {Icon && createElement(Icon, iconProps)}
            <Text>{label}</Text>
        </Box>
    )
}
